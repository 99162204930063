import { createTheme } from '@mui/material'

const bluechemTheme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    primary: {
      main: 'rgb(22, 65, 148)',
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1134,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      }
    }
  }
})

export default bluechemTheme
