import React, {FunctionComponent} from "react";
import {default as ThemeTopLayout} from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import { TopLayoutProps }  from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import {CssBaseline} from '@mui/material'

import '../../css/@wordpress/block-library/build-style/style.css';
import '../../css/@wordpress/block-library/build-style/theme.css';
import '../../css/misc.css';


const TopLayout:FunctionComponent<TopLayoutProps> = ({ children, theme }) => {

    return <ThemeTopLayout theme={theme}>
        <CssBaseline />
        {children}
    </ThemeTopLayout>
}

export default TopLayout