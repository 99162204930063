exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-post-tsx": () => import("./../../../src/pages/blog/post.tsx" /* webpackChunkName: "component---src-pages-blog-post-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-contact-tsx": () => import("./../../../src/pages/kontakt/contact.tsx" /* webpackChunkName: "component---src-pages-kontakt-contact-tsx" */),
  "component---src-pages-page-tsx": () => import("./../../../src/pages/page.tsx" /* webpackChunkName: "component---src-pages-page-tsx" */),
  "component---src-pages-proizvodi-category-tsx": () => import("./../../../src/pages/proizvodi/category.tsx" /* webpackChunkName: "component---src-pages-proizvodi-category-tsx" */),
  "component---src-pages-proizvodi-index-tsx": () => import("./../../../src/pages/proizvodi/index.tsx" /* webpackChunkName: "component---src-pages-proizvodi-index-tsx" */),
  "component---src-pages-proizvodi-product-tsx": () => import("./../../../src/pages/proizvodi/product.tsx" /* webpackChunkName: "component---src-pages-proizvodi-product-tsx" */)
}

